<template lang='pug'>
div(v-resize='onResize', @click='visible = false')
  v-toolbar.mb-6(flat, style='margin-left: -15px; height: 60px')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px', v-if='!isOpen', @click='setWidth')
    v-toolbar-title(v-if='isOpen') {{ $t("treatmentRecordsTitle") }}
    h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{ $t("treatmentRecordsTitle") }}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
    v-spacer
    div(:style='{ position: "relative", top: isOpen ? "0" : "10px" }')
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, dark, color='orange', @click='add()', v-bind='attrs', v-on='on')
            v-icon mdi-plus
        span {{ $t("addTreatment") }}
  v-row(style='margin-left: -15px; height: 40px; margin-bottom: 30px; width: 100%')
    v-col(cols='12', :sm='isOpen ? 1 : 5', md='2')
      el-input.input-with-select(
        :placeholder="$t('pleaseEnterContent')",
        v-model='userName',
        clearable,
        @clear='inputClear',
        @click.stop.native='isOpen ? (visible = false) : (visible = true)',
        @focus.stop='keyboardShowClick'
      )
        el-select(v-model='select', slot='prepend', :placeholder="$t('selectPlease')", @change='searchType', clearable, @clear='selectClear')
          el-option(:label="$t('software')", value='app')
          el-option(:label='numberName ? numberName : $t("number")', value='hospital_number')
          el-option(:label='typeUsername == "1" ? $t("thUserName") : $t("thPatientName")', value='name')
          el-option(
            :label='bedNumberName === "true" || bedNumberName == "" ? (typeUsername == "1" ? $t("userNameNumber") : $t("patientNumber")) : bedNumberName',
            value='patient_number',
            v-if='bedNumberName !== "false"'
          )
          el-option(:label='partition === "true" || partition == "" ? $t("subregion") : partition', value='bed_number', v-if='partition !== "false"')
    v-col(cols='12', :sm='isOpen ? 4 : 6', md='3')
      el-date-picker(
        v-model='dates',
        type='daterange',
        align='right',
        unlink-panels,
        :range-separator="$t('to')",
        :start-placeholder="$t('thStartDate')",
        :end-placeholder="$t('thStartDate')",
        :picker-options='pickerOptions',
        style='width: 80%',
        clearable,
        @change='dataClear'
      )
    <!-- v-col(cols="12", sm="1", md="1" style="margin-left: 50px;margin-top: -13px") -->
    div(:style='{ marginLeft: isOpen ? "-80px" : "-50px" }')
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.back(dark, color='orange', @click='searchClick()', v-bind='attrs', v-on='on')
            v-icon.iconfont.icon-31sousuo
        span {{ $t("search") }}
    div(style='margin-left: 20px')
      v-btn.back(dark, color='orange', @click='exports()')
        span {{ $t("derive") }}

  div(ref='resizableDiv', v-if='isOpen')
    v-data-table(
      fixed-header,
      :height='dataTableHeight',
      :headers='headers',
      :items='treatmentMounted',
      :search='search',
      :items-per-page='itemsPerPage',
      :footer-props='{ itemsPerPageOptions: [itemsPerPage] }',
      disable-sort
    )
      template(v-slot:item.active='{ item }')
        v-btn.disable-events(elevation='0', v-if='item.active', block, color='warning', rounded)
          v-icon(left) mdi-youtube-subscription
          | {{ $t("inTraining") }}
        v-btn.disable-events(elevation='0', v-else, block, disabled, rounded, color='primary') {{ $t("trainingFinished") }}
      template(v-slot:item.app='{ item }')
        div(v-if='item.app')
        v-row.mx-0(align='center')
          v-img(:src='getThumbnailSrc(item.app.thumbnail)', width='64', max-width='64', height='36', max-height='36')
          v-divider.mx-4(inset, vertical)
          pre.body-1 {{ item.app.name }}
      template(v-slot:item.patientName='{ item }')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-account
          pre.body-1(v-if='item.patient') {{ item.patient.name }}
      template(v-slot:item.hospitalNumber='{ item }')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-clipboard-account
          pre.body-1(v-if='item.patient') {{ item.patient.hospitalNumber }}
      template(v-slot:item.patientBedNumber='{ item }', v-if='partition !== "false"')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-lock-pattern
          pre.body-1(v-if='item.patient') {{ item.patient.bedNumber }}
      template(v-slot:item.patientPatientNumber='{ item }')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-bed
          pre.body-1(v-if='item.patient') {{ item.patient.patientNumber }}
      template(v-slot:item.score='{ item }')
        span
          v-btn(icon, color='black', @click='writeScore(item)')
            span {{ scoreHandler(item) }}

      template(v-slot:item.model.CreatedAt='{ item }')
        span
          v-btn(icon, color='black', @click='edit(item)')
            //- span {{ item.model.CreatedAt.split('T')[0] }}
            span {{ treatmentDate(item.applicationStartTime.slice(0, 4) == "0001" ? item.model.CreatedAt : item.applicationStartTime, displayTime) }}
      template(v-slot:item.actions='{ item }')
        span
          v-tooltip(bottom, v-if='hasDetailPage(item)')
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, v-bind='attrs', v-on='on', @click='goToDetail(item)')
                v-icon mdi-finance
            span {{ $t("actionReportTooltip") }}
        span(v-if='item.active == true')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, color='warning', @click='terminate(item)', v-bind='attrs', v-on='on')
                v-icon mdi-stop-circle-outline
            span {{ $t("actionTerminateTooltip") }}
        span()
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                v-icon mdi-delete
            span {{ $t("actionRemoveTooltip") }}

  div(ref='resizableDiv', v-if='!isOpen')
    v-data-table.elevation-1(
      fixed-header,
      :height='visible && enableTouch ? 750 : dataTableHeight',
      :headers='headers',
      :items='treatmentMounted',
      :search='search',
      :items-per-page='itemsPerPage',
      :footer-props='{ itemsPerPageOptions: [itemsPerPage] }',
      :expanded='expanded',
      item-key='model.ID',
      @click:row='clicked'
    )
      template(v-slot:expanded-item='{ headers, item }')
        td(:colspan='headers.length', style='background: #f9f7f7; border-top: none')
          v-row(style='font-size: 12px; display: flex; justify-content: space-between; width: 100%')
            div
              span(style='position: relative; left: 15px; top: 6px') {{ item.active ? "inTraining" : "trainingFinished" }}
              span(style='position: relative; left: 50px; top: 6px') {{ item.app.name }}
            div
              span(:style='{ position: "relative", right: isAdmin ? "15px" : "65px" }') {{ treatmentDate(item.applicationStartTime.slice(0, 4) == "0001" ? item.model.CreatedAt : item.applicationStartTime, displayTime) }}
              span(v-if='isAdmin', style='position: relative; right: -6px')
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                      v-icon mdi-delete
                  span {{ $t("actionRemoveTooltip") }}
      template(v-slot:item.active='{ item }')
        span(v-if='item.active == true')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, color='warning', @click='terminate(item)', v-bind='attrs', v-on='on')
                v-icon mdi-stop-circle-outline
            span {{ $t("actionTerminateTooltip") }}
        img(src='@/assets/complete.png', width='18', style='margin-right: 5px', v-else) 
      template(v-slot:item.app='{ item }')
        div(v-if='item.app')
          span
            v-img(
              :src='getThumbnailSrc(item.app.thumbnail)',
              width='64',
              max-width='64',
              height='36',
              max-height='36',
              style='margin-left: 20px'
            )
          <!-- v-divider.mx-4(inset, vertical)
          pre.body-1 {{ item.app.name }} -->
      template(v-slot:item.patientName='{ item }')
        span
          pre.body-1(v-if='item.patient') {{ item.patient.name }}
      template(v-slot:item.hospitalNumber='{ item }')
        span
          pre.body-1(v-if='item.patient') {{ item.patient.hospitalNumber }}
      template(v-slot:item.patientBedNumber='{ item }', v-if='partition !== "false"')
        span
          pre.body-1(v-if='item.patient') {{ item.patient.bedNumber }}
      template(v-slot:item.patientPatientNumber='{ item }')
        span
          pre.body-1(v-if='item.patient') {{ item.patient.patientNumber }}
      template(v-slot:item.score='{ item }')
        span
          v-btn(icon, color='black', @click='writeScore(item)')
            span {{ scoreHandler(item) }}

      template(v-slot:item.model.CreatedAt='{ item }')
        span
          v-btn(icon, color='black', @click='edit(item)')
            span {{ treatmentDatePickUp(item.applicationStartTime.slice(0, 4) == "0001" ? item.model.CreatedAt : item.applicationStartTime) }}
            <!-- span {{ treatmentDate(item.applicationStartTime.slice(0, 4) == '0001' ? item.model.CreatedAt : item.applicationStartTime, displayTime) }} -->
      template(v-slot:item.actions='{ item }')
        span
          v-tooltip(bottom, v-if='hasDetailPage(item)')
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, v-bind='attrs', v-on='on', @click='goToDetail(item)')
                v-icon mdi-finance
            span {{ $t("actionReportTooltip") }}

  v-dialog(v-model='confirmDialog', @click:outside='confirmCancel()', width='500')
    v-card
      v-card-title.warning.headline.mb-6
        h4(style='color: white') {{ $t("drTitle") }}
      v-card-text
        h3 {{ $t("drMsg") }}
      v-card-actions
        v-spacer
        v-btn(text, @click='confirmCancel()') {{ $t("drCancel") }}
        v-btn(text, @click='confirmConfirm()') {{ $t("drConfirm") }}

  v-dialog(v-model='writeScoreDialog', @click:outside='writeScoreCancel()', width='500')
    v-card
      v-card-title.primary.headline.mb-6
        h4(style='color: white') {{ $t("manualInput") }}
      v-card-text
        v-text-field(solo, dense, v-model='scoreOverwrite', type='number', @input='onWrite')
      v-card-actions
        v-spacer
        v-btn(text, @click='writeScoreCancel()') {{ $t("drCancel") }}
        v-btn(text, @click='writeScoreReset()') {{ $t("reset") }}
        v-btn(text, @click='writeScoreConfirm()') {{ $t("drConfirm") }}

  v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}

  NewTreatment(ref='newTreatment', :monitor='monitor')
  UpdateDateDialog(
    :dialog='dialog',
    :treatment='treatmentEditDate',
    @cancelClick='cancelHandleClick',
    @clearClick='clearHandleClick',
    @saveClick='saveHandleClick'
  )
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { treatment } from '../api'
import PatientMini from './PatientMini'
import DeviceMini from './DeviceMini'
import AppMini from './AppMini'
import NewTreatment from './NewTreatment'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import UpdateDateDialog from './Treatments/updateDateDialog.vue'
import Keyboard from 'vue-keyboard-zh'
import { NavigationDrawer } from '../components'

export default {
  data: () => ({
    input: '',
    visible: false,
    isOpen: false,
    expanded: [],
    dialog: false,
    dataTableHeight: 0,
    itemsPerPage: 0,
    search: '',
    userName: '',
    ID: '',
    scoreData: [],
    treatmentMounted: [],
    dates: '',
    confirmDialog: false,
    writeScoreDialog: false,
    treatmentIdDel: '',
    snackbar: false,
    snackbarColor: '',
    notifMsg: '',
    monitor: '',
    pickerOptions: {
    },
    select: 'name',
    startTime: '',
    endTime: '',
    scoreOverwrite: undefined,
    scoreOverwriteTreatmentId: undefined,
    originalScore: undefined,
    treatmentEditDate: undefined,
    getData: [],
    removeApp: {},
    appsData: [],
    categoryType: '0,1,2,3,4,5,6,7,8',
    navShow: false
  }),

  watch: {
    treatments: function (val) {
      if (this.userName == '' && this.endTime == '') {
        this.treatmentMounted = []
        val.forEach(x => {
          if (x.app != undefined) {
            this.treatmentMounted.push(x)
          }
        })
        this.treatmentMounted.sort(
          (a, b) =>
            new Date(b.applicationStartTime.slice(0, 4) == '0001' ? b.model.CreatedAt : b.applicationStartTime) -
            new Date(a.applicationStartTime.slice(0, 4) == '0001' ? a.model.CreatedAt : a.applicationStartTime)
        )
      } //else {
      //this.searchClick()
      //}
    }
  },

  async mounted() {
    this.resize()
    this.pickerOptions = {
      shortcuts: [
        {
          text: this.$t("lastWeek"),
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: this.$t("lastMonth"),
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: this.$t("lastThreeMonths"),
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }
      ]
    }
    window.addEventListener('resize', this.resize, false)
    const logToken = JSON.parse(window.localStorage.getItem('vuex'))
    const adminId = logToken.users.userId
    const role = logToken.users.role
    const userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
    const treatmentsList = await treatment.index(this.total ? this.total : 1000, '', this.userName, '', '', userId)
    this.getData =
      treatmentsList.data.length > 0
        ? treatmentsList.data.map(x => {
          if (this.appsAll != '') {
            const app = this.appsAll.find(y => y.model.ID == x.appId)
            if (app) x.app = app
          }
          const patient = this.patients.find(y => y.model.ID == x.patientId)
          if (patient) { x.patient = patient } else { x.patient = "" }
          const device = this.devices.find(y => y.model.ID == x.deviceId)
          if (device) x.device = device
          return x
        })
        : []
    this.treatmentMounted = []

    this.getData.forEach(x => {
      if (x != undefined) {
        if (x.app != undefined) {
          this.treatmentMounted.push(x)
        }
      }
    })
    this.treatmentMounted.sort(
      (a, b) =>
        new Date(b.applicationStartTime.slice(0, 4) == '0001' ? b.model.CreatedAt : b.applicationStartTime) -
        new Date(a.applicationStartTime.slice(0, 4) == '0001' ? a.model.CreatedAt : a.applicationStartTime)
    )

  },

  methods: {
    setWidth() {
      this.navShow = true
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    keyboardShowClick(e) {
      if (this.isOpen) {
        this.input = ''
        this.visible = false
      } else {
        this.input = e.target
        if (!this.visible) this.visible = true
      }
    },
    cancel() {
      this.visible = false
    },
    clicked(value) {
      const index = this.expanded.indexOf(value)
      if (index === -1 && this.expanded.length === 0) {
        this.expanded.push(value)
      } else if (index == 0) {
        this.expanded.splice(index, 1)
      } else if (this.expanded.length !== 0) {
        this.expanded.splice(index, 1)
        //打开点击的行
        this.expanded.push(value)
      }
    },
    download() {
      const blob = new Blob([1, 2, 3, 4, 5, 6], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', 'download.csv')
      a.click()
    },
    async exports() {
      const startDate = this.dates
        ? new Date(this.dates[0]).getFullYear() + '-' + (new Date(this.dates[0]).getMonth() + 1) + '-' + new Date(this.dates[0]).getDate()
        : ''
      const endDate = this.dates
        ? new Date(this.dates[1]).getFullYear() + '-' + (new Date(this.dates[1]).getMonth() + 1) + '-' + new Date(this.dates[1]).getDate()
        : ''
      this.startTime = startDate ? startDate + ' ' + '00:00:00' : ''
      this.endTime = endDate ? endDate + ' ' + '23:59:59' : ''
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : '1') : adminId
      const params = {
        [this.select === 'patient_number' ? 'patientNumber' : this.select == 'hospital_number' ? 'hospitalNumber' : this.select]:
          this.userName,
        startTime: this.startTime,
        endTime: this.endTime,
        userId: userId.toString()
      }
      const res = await treatment.exports(params)
      const csv = await fetch(res.data.export_url)
      const blob = await csv.blob()
      const blobUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = blobUrl
      a.download = 'data.csv'
      a.click()
      a.remove()
    },
    getLocalStorage(query, show) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        isShow: show,
        type: String(query)
      }
      return obj1
    },
    treatmentDate(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', {
            hour: '2-digit',
            minute: '2-digit'
          })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    treatmentDatePickUp(dateString) {
      const date = new Date(dateString)
      const month = date.getMonth() + 1
      const days = date.getDate()
      return month + '/' + days
    },
    searchType(value) {
      this.select = value
    },
    inputClear() {
      this.userName = ''
      this.searchClick()
    },
    selectClear() {
      this.select = ''
      this.searchClick()
    },
    dataClear(value) {
      if (value == null) {
        this.startTime = ''
        this.endTime = undefined
        this.searchClick()
      }
    },
    async searchClick() {
      const startDate = this.dates
        ? new Date(this.dates[0]).getFullYear() + '-' + (new Date(this.dates[0]).getMonth() + 1) + '-' + new Date(this.dates[0]).getDate()
        : ''
      const endDate = this.dates
        ? new Date(this.dates[1]).getFullYear() + '-' + (new Date(this.dates[1]).getMonth() + 1) + '-' + new Date(this.dates[1]).getDate()
        : ''
      this.startTime = startDate ? startDate + ' ' + '00:00:00' : ''
      this.endTime = endDate ? endDate + ' ' + '23:59:59' : ''
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )

      const treatmentsList = await treatment.index(
        this.total ? this.total : 1000,
        this.select,
        this.userName,
        this.startTime,
        this.endTime,
        userId
      )
      this.searchTreatments(treatmentsList.data)
      if (this.apps.length > 0 || this.patients.length > 0 || this.devices.length > 0) {
        this.getData =
          treatmentsList.data.length > 0
            ? treatmentsList.data.map(x => {
              const app = this.appsAll.find(y => y.model.ID == x.appId)
              if (app) x.app = app
              const patient = this.patients.find(y => y.model.ID == x.patientId)
              if (patient) x.patient = patient
              const device = this.devices.find(y => y.model.ID == x.deviceId)
              if (device) x.device = device
              return x
            })
            : []
      }
      this.treatmentMounted = []
      this.getData.forEach(x => {
        if (x != undefined) {
          if (x.app != undefined && x.patient != undefined) {
            this.treatmentMounted.push(x)
          }
        }
      })
      this.treatmentMounted.sort(
        (a, b) =>
          new Date(b.applicationStartTime.slice(0, 4) == '0001' ? b.model.CreatedAt : b.applicationStartTime) -
          new Date(a.applicationStartTime.slice(0, 4) == '0001' ? a.model.CreatedAt : a.applicationStartTime)
      )
    },
    ...mapActions({
      searchTreatments: 'treatments/searchTreatments',
    }),
    remove(item) {
      this.confirmDialog = true
      this.treatmentIdDel = item?.model.ID
    },
    confirmCancel() {
      this.confirmDialog = false
    },
    async confirmConfirm() {
      try {
        await treatment.remove(this.treatmentIdDel)
        this.snackbarColor = 'success'
        this.notifMsg = `${this.$t('notifSuccess')}`
      } catch (err) {
        this.snackbarColor = 'error'
        this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
      }
      this.snackbar = true
      this.confirmCancel()
    },
    onResize() {
      const height = window.innerHeight - this.$refs.resizableDiv.getBoundingClientRect().y - 12 - 37
      this.dataTableHeight = height
      this.itemsPerPage = parseInt((height - 48) / 48)
    },
    add() {
      this.$refs.newTreatment.dialog = true
      this.monitor = 'monitor'
    },
    async terminate(item) {
      const treatmentId = item?.model.ID
      await treatment.terminate(treatmentId)
    },

    getThumbnailSrc(src) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${src}`
    },
    async goToDetail(item) {
      this.$router.push({
        path: `/treatments/${item?.model.ID}`,
        query: { appId: item.appId, patientId: item.patientId, treatmentId: item.model.ID }
      })
    },
    hasDetailPage(item) {
      if (
        [
          'com.xzs.citstorerelease',
          'com.xzs.dragonbeat',
          'com.xzs.WheelMaze',
          'com.xzs.shadow',
          'com.xzs.pingpongsimulator',
          'com.xzs.shootinggallery',
          'com.xzs.taichi',
          'com.xzs.grow',
          'com.xzs.bbqflip',
          'com.xzs.memoryroom',
          'com.xzs.sandtableroom',
          'com.xzs.catharsisroom',
          'com.xzs.cognitiveassessment',
          'com.xzs.melodicpitch',
          'com.xzs.jellyfishswim',
          'com.xzs.snowballfight',
          'com.xzs.mathboxing',
          'com.xzs.londontower',
          'com.xzs.acrophobia',
          'com.xzs.painrelief',
          'com.XZS.VREyeTrackingAttentionAssessmentSystem',
          'com.xzs.forestfriends',
          'com.xzs.fruitparty',
          'com.xzs.golfclub',
          'com.xzs.puppydollhouse',
          'com.xzs.vrtennis',
          'com.xzs.breathe',
          'com.xzs.rabbitstore',
          'com.xzs.sailingadventure',
          'com.xzs.brushteeth',
          'com.xzs.hidenseek',
          'com.xzs.laundry',
          'com.xzs.Basketball',
          'com.xzs.mindratingscalesystem',
          'com.xzs.icejourney',
          'com.xzs.citstoregesture',
          'com.xzs.shootinggallery2',
          'com.xzs.adlsupermarket',
          'com.xzs.fruittrain',
          'com.xzs.musicbeat',
          'com.xzs.ComaArousal',
          'com.xzs.happypatting',
          'com.xzs.simulatedtown',
          'com.xzs.mathboxing2024',
          'com.xzs.postalsearch',
          'com.xzs.postcard_mession',
          'com.xzs.postalblitz',
          JSON.parse(item.settings).breathMonitor == true ? 'com.xzs.videoplayer' : '',
          JSON.parse(item.settings).breathMonitor == true ? 'com.xzs.spp' : ''
        ].includes(item.app.packageName)
      ) {
        return true
      } else {
        return false
      }
    },
    // 编辑日期
    edit(item) {
      if (!this.enabledTime) return
      this.treatmentEditDate = item
      this.dialog = true
    },
    cancelHandleClick() {
      this.dateList = { CreatedAt: '', ID: this.ID }
      this.dialog = false
    },
    clearHandleClick() {
      this.dateList = { CreatedAt: '', ID: this.ID }
    },
    saveHandleClick() {
      this.dialog = false
    },
    scoreHandler(item) {
      return item.scoreOverwrite
    },
    rawScore(item) {
      return item.app.packageName == 'com.xzs.sandtableroom' ||
        item.app.packageName == 'com.xzs.catharsisroom' ||
        JSON.parse(item.settings).scoreSwitch !== undefined
        ? JSON.parse(item.settings).scoreSwitch
          ? item.score
          : '/'
        : item.score
    },
    writeScore(item) {
      if (!this.enableScoreOverwrite) return
      this.scoreOverwrite = item.scoreOverwrite
      this.scoreOverwriteTreatmentId = item.model.ID
      this.originalScore = item.score
      this.writeScoreDialog = true
    },
    writeScoreCancel() {
      this.writeScoreDialog = false
    },
    writeScoreReset() {
      const request = {
        treatmentId: this.scoreOverwriteTreatmentId,
        scoreOverwrite: parseFloat(this.originalScore)
      }
      treatment.overwriteScore(request)
      this.writeScoreDialog = false
    },
    writeScoreConfirm() {
      const treatmentFiltered = this.treatmentMounted.find(x => x.model.ID == this.scoreOverwriteTreatmentId)
      if (treatmentFiltered) treatmentFiltered.scoreOverwrite = parseFloat(this.scoreOverwrite)
      const request = {
        treatmentId: this.scoreOverwriteTreatmentId,
        scoreOverwrite: parseFloat(this.scoreOverwrite)
      }
      treatment.overwriteScore(request)
      this.writeScoreDialog = false
    },
    onWrite() {
      if (this.scoreOverwrite < 0) {
        this.$nextTick(() => {
          this.scoreOverwrite = 0
        })
      }
      if (this.scoreOverwrite > 100) {
        this.$nextTick(() => {
          this.scoreOverwrite = 100
        })
      }
    }
  },

  components: {
    PatientMini,
    DeviceMini,
    AppMini,
    NewTreatment,
    UpdateDateDialog,
    Keyboard,
    NavigationDrawer
  },

  computed: {
    ...mapGetters({
      treatments: 'treatments/treatmentsWADP',
      isAdmin: 'users/isAdmin',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      total: 'setting/total',
      displayTime: 'setting/displayTime',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enabledTime: 'setting/enabledTime',
      enableTouch: 'setting/enableTouch'
    }),

    ...mapState({
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items,
      appsAll: state => state.apps.appsAllData
    }),

    headers() {
      const headers = []
      if (this.partition !== 'false') {
        headers.push(
          { text: this.$t('thActive'), value: 'active', align: this.isOpen ? 'start' : 'center' },
          { text: this.$t('thAppName'), value: 'app', align: this.isOpen ? 'start' : 'center', sortable: false },
          {
            text: this.typeUsername == '1' ? this.$t('thUserName') : this.$t('thPatientName'),
            value: 'patientName',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.numberName == '' ? this.$t('number') : this.numberName,
            value: 'hospitalNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.partition === 'true' || this.partition == '' ? this.$t("subregion") : this.partition,
            value: 'patientBedNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          this.bedNumberName != 'false'
            ? {
              text:
                this.bedNumberName === 'true' || this.bedNumberName == ''
                  ? this.typeUsername == '1'
                    ? this.$t('thUserNameNumber')
                    : this.$t('thPatientPatientNumber')
                  : this.bedNumberName,
              value: 'patientPatientNumber',
              align: this.isOpen ? 'start' : 'center'
            }
            : '',
          { text: this.$t('trainingScore'), value: 'score', align: this.isOpen ? 'start' : 'center' },
          { text: this.$t('thDate'), value: 'model.CreatedAt', align: this.isOpen ? 'start' : 'center' },
          { text: this.$t('thActions'), value: 'actions', align: this.isOpen ? 'end' : 'center', sortable: false }
        )
      } else {
        headers.push(
          { text: this.$t('thActive'), value: 'active', align: this.isOpen ? 'start' : 'center' },
          { text: this.$t('thAppName'), value: 'app', align: this.isOpen ? 'start' : 'center' },
          {
            text: this.typeUsername == '1' ? this.$t('thUserName') : this.$t('thPatientName'),
            value: 'patientName',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.numberName == '' ? this.$t('number') : this.numberName,
            value: 'hospitalNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          this.bedNumberName != 'false'
            ? {
              text:
                this.bedNumberName === 'true' || this.bedNumberName == ''
                  ? this.typeUsername == '1'
                    ? this.$t('thUserNameNumber')
                    : this.$t('thPatientPatientNumber')
                  : this.bedNumberName,
              value: 'patientPatientNumber',
              align: this.isOpen ? 'start' : 'center'
            }
            : '',
          { text: this.$t('trainingScore'), value: 'score', align: this.isOpen ? 'start' : 'center' },
          { text: this.$t('thDate'), value: 'model.CreatedAt', align: this.isOpen ? 'start' : 'center' },
          { text: this.$t('thActions'), value: 'actions', align: this.isOpen ? 'end' : 'center' }
        )
      }
      return headers
    }
  }
}
</script>

<style lang="sass" scoped>
.disable-events
  pointer-events: none
.icon-31sousuo
  color: #fff
::v-deep .v-btn:not(.v-btn--round).v-size--default
  padding: 0
  min-width: 45px
  height: 40px
.back
  margin-top: 11px
  margin-left: -4.8%
::v-deep .el-input__inner
  border: 1px solid #9E9E9E
::v-deep .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner
  border: 2px solid #1976D2 !important
::v-deep .el-date-editor .el-range-input
  font-size: 16px
  font-family: 400
  color: #929292
::v-deep .el-select .el-input
  width: 110px
::v-deep .el-select>.el-input
  border-top: 1px solid #9E9E9E
  border-left: 1px solid #9E9E9E
  border-bottom: 1px solid #9E9E9E
  height: 39.5px
::v-deep .el-input .el-input__inner:focus
  border-color: none !important
  border: 2px solid #1976D2 !important
::v-deep .input-with-select .el-input-group__prepend
  background-color: #fff
.icon-fenquguanli
  font-size: 26px
  margin-right: 8px
  color: #757575
.elevation-1
  width: 100%
::v-deep .elevation-1 > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th
  padding: 0 8px !important
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th
  padding: 0 8px !important
::v-deep .elevation-1 > .v-data-table__wrapper > table > thead > tr > th > .v-data-table-header__icon
  font-size: 8px !important
  position: relative
  top: 1px
#keyboard
  position: absolute
  top: auto !important
  bottom: 0
  z-index: 999
  padding: 14px 21px
::v-deep .v-data-table__wrapper::-webkit-scrollbar
  width: 0px
::v-deep .elevation-1>.v-data-table__wrapper tbody tr.v-data-table__expanded
  background: #F9F7F7 !important
::v-deep .theme--light.elevation-1 > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row)
  border-bottom: none !important
::v-deep .elevation-1
  box-shadow: none !important
.navShow
  animation: expanded 0.5s ease
.navHide
  display: none
.mask
  width: 100%
  height: 100%
  background: rgba(0,0,0,0.3)
  position: fixed
  top: 0
  z-index: 998
</style>
