import 'jspdf-autotable'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import './Yahei-normal'

export const printPDF = content => {
  import('jspdf')
    .then(({ jsPDF }) => {
      const doc = new jsPDF()
      doc.setFont('Yahei')

      import('./Yahei-normal').then(() => {
        doc.setFont('Yahei')
        const width = doc.internal.pageSize.getWidth()
        const height = doc.internal.pageSize.getHeight()
        const middle = width / 2
        const left = { align: 'left' }
        const center = { align: 'center' }
        const right = { align: 'right' }
        if (content.logo) {
          const img = new Image()
          img.src = `${VUE_APP_SERVER_ENDPOINT}/assets/${content.logo}`
          doc.addImage(encodeURIComponent(img.src), 10, 11, 22, 22, center)
        }

        doc.setFontSize(16)
        doc.text(content.institution, middle, 20, center)
        doc.setFontSize(14)
        doc.text(content.reportName, middle, 30, center)
        doc.line(10, 38, width - 10, 38)

        doc.setFontSize(12)
        doc.text(`姓名：${content.patient.name}`, 10, 45, left)
        doc.text(`性别：${content.patient.gender}`, middle, 45, center)
        doc.text(`年龄：${calcAge(content.patient.birthday)}`, width - 10, 45, right)
        doc.text(`${content.numberName ? content.numberName + '：' : '编号：'}${content.patient.hospitalNumber}`, 10, 52, left)
        doc.text(
          `${content.bedNumberName != 'false'
            ? content.bedNumberName == 'true'
              ? content.typeUsername == '1'
                ? '用户号：'
                : '病床号：'
              : content.bedNumberName + '：'
            : ''
          }${content.bedNumberName !== 'false' ? content.patient.patientNumber : ''}`,
          middle + 2,
          52,
          center
        )
        doc.text(
          `${content.partition !== 'false' ? (content.partition == 'true' ? '分区：' : content.partition + '：') : ''}${content.partition !== 'false' ? content.patient.bedNumber : ''
          }`,
          width - 10,
          52,
          right
        )
        // doc.text(`建档日期：${calcDate(content.patient.model.CreatedAt)}`, width - 10, 52, right)
        doc.line(10, 57, width - 10, 57)

        const tableData = genTableData(content)
        doc.autoTable({
          theme: 'striped',
          styles: { font: 'Yahei', fontSize: 10 },
          margin: { left: 10, right: 10 },
          startY: 65,
          head: [
            [
              '编号',
              '治疗项目',
              '训练目标',
              '训练得分',
              `${content.treatmentTime ? '治疗时长(分钟)' : ''}`,
              { content: '训练开始时间', styles: { halign: 'right' } },
              { content: content.enableEndTime ? '训练结束时间' : '', styles: { halign: 'right' } }
            ]
          ],
          body: tableData
        })

        doc.setFontSize(8)
        doc.text(`打印时间：${calcDateTime(new Date())}`, 10, height - 15, left)
        doc.text('*此报告仅供临床参考', 10, height - 10, left)
        doc.setFontSize(12)
        doc.text('签字：', width / 2 + 20, height - 12, left)

        window.open(doc.output('bloburl'), '_blank')
      })
    })
    .catch(err => console.log(err))
}

function genTableData(content) {
  const _treatments = content.treatments
  _treatments.sort((a, b) => {
    return (
      new Date(a.applicationStartTime.slice(0, 4) == '0001' ? a.model.CreatedAt : a.applicationStartTime) -
      new Date(b.applicationStartTime.slice(0, 4) == '0001' ? b.model.CreatedAt : b.applicationStartTime)
    )
  })
  return _treatments.reduce((p, c) => {
    //const score = content.enableScoreOverwrite ? c.scoreOverwrite : c.scoreStatus
    // const score = c.scoreOverwrite
    p.push([
      p.length + 1,
      c.name,
      c.target, 
      // c.device.sn,
      c.score,
      c.treatmentTime,
      // c.applicationStartTime,
      // {
      //   content: content.treatmentTime
      //     ? calcTreatmentTime(
      //       c.applicationEndTime,
      //       c.applicationStartTime.slice(0, 4) == '0001' ? c.model.CreatedAt : c.applicationStartTime
      //     )
      //     : '',
      //   styles: { halign: 'left' }
      // },
      {
        content: calcDateTime(
          c.applicationStartTime.slice(0, 4) == '0001' ? c.model.CreatedAt : c.applicationStartTime,
          content.displayTime
        ),
        styles: { halign: 'right' }
      },
      { content: content.enableEndTime ? calcEndDateTime(c.applicationEndTime, content.displayTime) : '', styles: { halign: 'right' } }
    ])
    return p
  }, [])
}

function calcAge(dateString) {
  var birthday = +new Date(dateString)
  return ~~((Date.now() - birthday) / 31557600000)
}

// function calcTreatmentTime(endTime, startTime) {
//   // const timeGup = (new Date(endTime).getTime() -  8 * 60 * 60 * 1000) - (new Date(startTime).getTime() -  8 * 60 * 60 * 1000)
//   // return new Date(timeGup).getMinutes()+' 分钟'
//   var minutes = 0
//   if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
//     minutes = 0
//   } else {
//     const diff = Math.abs(new Date(endTime) - new Date(startTime))
//     minutes = Math.round(diff / 1000 / 60)
//   }

//   return minutes
// }

function calcDateTime(dateString, displayTime = true) {
  const date = new Date(dateString)
  if (displayTime) {
    return (
      date.toLocaleDateString('zh-CN', { hour12: false }) + ' ' + date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
    )
  } else {
    return date.toLocaleDateString('zh-CN', { hour12: false })
  }
}

function calcEndDateTime(dateString, displayTime = true) {
  const date = new Date(dateString)
  if (displayTime) {
    return (
      date.toLocaleDateString('zh-CN', { hour12: false }) + ' ' + date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
    )
  } else {
    return date.toLocaleDateString('zh-CN', { hour12: false })
  }
}
