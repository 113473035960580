import 'jspdf-autotable'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import './Yahei-normal'
import html2Canvas from 'html2canvas'

export const patientPdf = content => {
    import('jspdf').then(({jsPDF}) => {
        const doc = new jsPDF()
        doc.setFont('Yahei')
        const width = doc.internal.pageSize.getWidth()
        const height = doc.internal.pageSize.getHeight()
        const middle = width / 2 - 20
        const titleMiddle = width / 2
        const left = { align: 'left' }
        const center = { align: 'center' }
        if (content.logo) {
            const img = new Image()
            img.src = `${VUE_APP_SERVER_ENDPOINT}/assets/${content.logo}`
            doc.addImage(encodeURIComponent(img.src), 10, 11, 22, 22, center)
        }
        doc.setFontSize(16)
        doc.text(content.institution, titleMiddle, 20, center)
        doc.setFontSize(14)
        doc.text(content.reportName, titleMiddle, 30, center)
        doc.line(10, 38, width - 10, 38)
        doc.setFontSize(12)
        doc.text(`姓名：${content.patientsInfo.patient.name}`, 10, 45, left)
        doc.text(`性别：${content.patientsInfo.patient.gender}`, middle, 45, left)
        doc.text(`年龄：${calcAge(content.patientsInfo.patient.birthday)}`, width - 30, 45, left)
        doc.text(`${content.numberName ? content.numberName + '：' : '编号：'}${content.patientsInfo.patient.hospitalNumber}`, 10, 52, left)
        doc.text(
            `${content.bedNumberName != 'false'
                ? content.bedNumberName == 'true'
                    ? content.typeUsername == '1'
                        ? '用户号：'
                        : '病床号：'
                    : content.bedNumberName + '：'
                : ''
            }${content.bedNumberName !== 'false' ? content.patientsInfo.patient.patientNumber : ''}`,
            middle,
            52,
            left
        )
        doc.text(
            `${content.partition !== 'false' ? (content.partition == 'true' ? '分区：' : content.partition + '：') : ''}${content.partition !== 'false' ? content.patientsInfo.patient.bedNumber : ''
            }`,
            width - 30,
            52,
            left
        )
        doc.line(10, 57, width - 10, 57)
        var diagnosis = ['医生诊断']
        var diagnosisArr = [[content.diagnosis]]
        var columns1 = ['评估结果']
        var rows1 = [
            [
                content.patientsInfo.Score[1] !== null
                    ? content.patientsInfo.Score[1].model.CreatedAt.slice(0, 10).replace(/-/g, '.') +
                    '  mmse量表：' +
                    content.patientsInfo.Score[1].score
                    : ''
            ],
            [
                content.patientsInfo.Score[0] !== null
                    ? content.patientsInfo.Score[0].model.CreatedAt.slice(0, 10).replace(/-/g, '.') +
                    '  moCA量表：' +
                    content.patientsInfo.Score[0].score
                    : ''
            ],
            [
                content.testResult !== null
                    ? content.cognitiveTime +
                    '    认知功能评估：执行力—' +
                    content.patientsInfo.dimensionData.execution +
                    '    定向力—' +
                    content.patientsInfo.dimensionData.orientation +
                    '    注意力—' +
                    content.patientsInfo.dimensionData.attention +
                    '    记忆力—' +
                    content.patientsInfo.dimensionData.memory +
                    '    计算力—' +
                    content.patientsInfo.dimensionData.calculate
                    : ''
            ]
        ]
        var columns2 = ['治疗方案']
        var columns3 = ['治疗结果']
        // var tableSubTitle = ['', '', '', '', '', '']
        var rows3 = [
            [
                { content: '日期/分类', styles: { halign: 'center', valign: 'center' } },
                { content: '注意力', styles: { halign: 'center', valign: 'center' } },
                { content: '定向力', styles: { halign: 'center', valign: 'center' } },
                { content: '执行力', styles: { halign: 'center', valign: 'center' } },
                { content: '计算力', styles: { halign: 'center', valign: 'center' } },
                { content: '记忆力', styles: { halign: 'center', valign: 'center' } }
            ],
            [
                { content: content.cognitiveTime, styles: { halign: 'center', valign: 'center' } },
                { content: content.patientsInfo.dimensionData.attention, styles: { halign: 'center', valign: 'center' } },
                { content: content.patientsInfo.dimensionData.orientation, styles: { halign: 'center', valign: 'center' } },
                { content: content.patientsInfo.dimensionData.execution, styles: { halign: 'center', valign: 'center' } },
                { content: content.patientsInfo.dimensionData.calculate, styles: { halign: 'center', valign: 'center' } },
                { content: content.patientsInfo.dimensionData.memory, styles: { halign: 'center', valign: 'center' } }
            ],
            // ...content.patientsInfo.dimensionResult
            [
                { content: content.dimensionTime, styles: { halign: 'center', valign: 'center' } },
                {
                    content: content.patientsInfo.dimensionResult[0] === 0 ? '/' : content.patientsInfo.dimensionResult[0],
                    styles: { halign: 'center', valign: 'center' }
                },
                {
                    content: content.patientsInfo.dimensionResult[4] === 0 ? '/' : content.patientsInfo.dimensionResult[4],
                    styles: { halign: 'center', valign: 'center' }
                },
                {
                    content: content.patientsInfo.dimensionResult[1] === 0 ? '/' : content.patientsInfo.dimensionResult[1],
                    styles: { halign: 'center', valign: 'center' }
                },
                {
                    content: content.patientsInfo.dimensionResult[2] === 0 ? '/' : content.patientsInfo.dimensionResult[2],
                    styles: { halign: 'center', valign: 'center' }
                },
                {
                    content: content.patientsInfo.dimensionResult[3] === 0 ? '/' : content.patientsInfo.dimensionResult[3],
                    styles: { halign: 'center', valign: 'center' }
                }
            ]
        ]
        var columns4 = ['治疗记录', '', '', '']

        doc.autoTable({
            theme: 'striped',
            styles: { font: 'Yahei', fontSize: 10 },
            bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 10 },
            margin: { left: 10, right: 10 },
            startY: 60,
            head: [diagnosis],
            body: diagnosisArr
        })
        doc.autoTable({
            theme: 'striped',
            styles: { font: 'Yahei', fontSize: 10 },
            bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 10 },
            margin: { left: 10, right: 10 },
            startY: 78,
            head: [columns1],
            body: rows1
        })
        var rows2 = getTreatmentTable(content.patientsInfo.treatmentPlan)
        doc.autoTable({
            theme: 'striped',
            styles: { font: 'Yahei', fontSize: 10 },
            bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 10 },
            margin: { left: 10, right: 10 },
            startY: 115,
            head: [columns2],
            body: rows2
        })
        content.testResult !== null
            ? html2Canvas(content.printDom, {
                allowTaint: true,
                taintTest: false,
                useCORS: true,
                y: 0,
                dpi: window.devicePixelRatio * 4,
                scale: 4,
                padding: { left: 10, right: 10 }
            }).then(function (canvas) {
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                doc.addImage(
                    pageData,
                    'JPEG',
                    0,
                    content.patientsInfo.treatmentPlan.length == 0
                        ? content.patientsInfo.treatmentPlan.length * 10 + 143
                        : content.patientsInfo.treatmentPlan.length * 10 + 133,
                    content.isOpen ? 118 : 90,
                    50
                )

                doc.autoTable({
                    theme: 'striped',
                    styles: { font: 'Yahei', fontSize: 10 },
                    bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 0 },
                    margin: { left: 10, right: 10 },
                    startY:
                        content.patientsInfo.treatmentPlan.length == 0
                            ? content.patientsInfo.treatmentPlan.length * 10 + 132
                            : content.patientsInfo.treatmentPlan.length * 10 + 122,
                    head: [columns3],
                    body: []
                })

                doc.autoTable({
                    theme: 'grid',
                    styles: { font: 'Yahei', fontSize: 8, fillColor: '#F5F5F5', minCellHeight: 0 },
                    bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 21, minCellWidth: 10, fontSize: 8 },
                    margin: { left: 120, right: 10 },
                    startY:
                        content.patientsInfo.treatmentPlan.length == 0
                            ? content.patientsInfo.treatmentPlan.length * 10 + 140
                            : content.patientsInfo.treatmentPlan.length * 10 + 130,
                    tableWidth: 80, //表格宽度设置
                    head: false,
                    body: rows3
                })

                var pageInfo = doc.getCurrentPageInfo()

                const tableData = genTableData(content)
                tableData.unshift(['日期', '软件', '时长(min)', '得分'])
                doc.autoTable({
                    theme: 'striped',
                    styles: { font: 'Yahei', fontSize: 10 },
                    bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 10 },
                    margin: { left: 10, right: 10 },
                    startY:
                        pageInfo.pageNumber == 2
                            ? 24
                            : content.patientsInfo.treatmentPlan.length == 0
                                ? 202 + content.patientsInfo.treatmentPlan.length * 10
                                : 192 + content.patientsInfo.treatmentPlan.length * 10,
                    head: [columns4],
                    body: tableData
                })
                doc.setFontSize(8)
                doc.text(`打印时间：${calcDateTime(new Date())}`, 10, height - 15, left)
                doc.text('*此报告仅供临床参考', 10, height - 10, left)
                doc.setFontSize(12)
                doc.text('签字：', width / 2 + 20, height - 12, left)
                window.open(doc.output('bloburl'), '_blank')
            })
            : ''

        const tableData = content.testResult === null ? genTableData(content) : ''
        content.testResult === null
            ? doc.autoTable({
                theme: 'striped',
                styles: { font: 'Yahei', fontSize: 10 },
                bodyStyles: { fillColor: '#F5F5F5', minCellHeight: 10 },
                margin: { left: 10, right: 10 },
                startY:
                    content.testResult !== null
                        ? 202 + content.patientsInfo.treatmentPlan.length * 10
                        : 140 + content.patientsInfo.treatmentPlan.length * 10,
                head: [columns4],
                body: tableData
            })
            : ''
        content.testResult === null ? doc.setFontSize(8) : ''
        content.testResult === null ? doc.text(`打印时间：${calcDateTime(new Date())}`, 10, height - 15, left) : ''
        content.testResult === null ? doc.text('*此报告仅供临床参考', 10, height - 10, left) : ''
        content.testResult === null ? doc.setFontSize(12) : ''
        content.testResult === null ? doc.text('签字：', width / 2 + 20, height - 12, left) : ''
        content.testResult === null ? window.open(doc.output('bloburl'), '_blank') : ''
    })
}

function calcAge(dateString) {
    var birthday = +new Date(dateString)
    return ~~((Date.now() - birthday) / 31557600000)
}

function calcDateTime(dateString, displayTime = true) {
    const date = new Date(dateString)
    if (displayTime) {
        return (
            date.toLocaleDateString('zh-CN', { hour12: false }) + ' ' + date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
        )
    } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
    }
}

function genTableData(content) {
    const _treatments = content.patientsInfo.treatmentRecord
    _treatments.sort((a, b) => {
        return (
            new Date(a.applicationStartTime.slice(0, 4) == '0001' ? a.model.CreatedAt : a.applicationStartTime) -
            new Date(b.applicationStartTime.slice(0, 4) == '0001' ? b.model.CreatedAt : b.applicationStartTime)
        )
    })
    return _treatments.reduce((p, c) => {
        const score = c.scoreOverwrite
        p.push([
            {
                content: calcDateTime(
                    c.applicationStartTime.slice(0, 4) == '0001' ? c.model.CreatedAt : c.applicationStartTime,
                    content.displayTime
                ),
                styles: { halign: 'left' }
            },
            { content: c.app.name, styles: { halign: 'left' } },
            {
                content: calcTreatmentTime(
                    c.applicationEndTime,
                    c.applicationStartTime.slice(0, 4) == '0001' ? c.model.CreatedAt : c.applicationStartTime
                ),
                styles: { halign: 'left' }
            },
            score
        ])
        return p
    }, [])
}

function getTreatmentTable(treatmentPlane) {
    return treatmentPlane.reduce((p, c) => {
        const target = c.target.replace(/\n/g, ';').split(';')
        const t = target.map(x => {
            return x.slice(2)
        })
        p.push([[c.name + ' — ' + t.join(';')]])
        return p
    }, [])
}

function calcTreatmentTime(endTime, startTime) {
    var minutes = 0
    if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
        minutes = 0
    } else {
        const diff = Math.abs(new Date(endTime) - new Date(startTime))
        minutes = Math.round(diff / 1000 / 60)
    }

    return minutes
}